$(function () {
    $('#order-summary-content').on('click', '.goto-step', function () {
        var collaps = $(this).attr('href');
//        console.log('#collapse_' + collaps.replace('#', ''));
        $('.collapse').collapse('hide');
        $('#collapse_' + collaps.replace('#', '')).collapse('show');
    });
    $('.checkout-process .content').on('show.bs.collapse', function () {
        var container = $(this).parent().next();
        var toHide = container.find('.checkout-step:not(-complete)');
//        console.log(toHide);
        toHide.css("display", "none");
    })
});