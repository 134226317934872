$(document).ready(function () {


    $('.product-quantity').on('click', '#quantity_decrease', function (event) {
        event.preventDefault();
        var quantity = parseInt($('#quantity_wanted').val());
        if (quantity > 1) {
            $('#quantity_wanted').val(quantity - 1);
        }
    })
    $('.product-quantity').on('click', '#quantity_increase', function (event) {
        event.preventDefault();
        var quantity = parseInt($('#quantity_wanted').val());
        $('#quantity_wanted').val(quantity + 1);
    })


    var lightboxItem = 0;
    var lightboxItems = [
    ];

    $('.product-images').on('click', 'li', function () {
        var img = $(this).find('img');
        $('.product-cover').find('img').attr('src', img.data('largeUrl'));
        $('.product-cover').find('a').attr('href', img.data('largeUrl'));
        lightboxItem = img.data('itemNr');
    })

    $(".product-images li img").each(function () {
        lightboxItems.push({src: $(this).data('largeUrl')});
        lightboxItem = 0;
    });
    if (!lightboxItems.length) {
        lightboxItems.push({src: $('.popup-link').attr('href')});
    }
    console.log(lightboxItems);
    $('.popup-link').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        },
        items: lightboxItems,
        callbacks: {
            open: function (item) {
                this.goTo(lightboxItem);
                console.log(lightboxItem);
                console.log($(this.ev[0]));
            },
        }
    });


    prestashop.blockcart = prestashop.blockcart || {};

    var showModal = prestashop.blockcart.showModal || function (modal) {
        var $body = $('body');
        $body.append(modal);
        $body.on('click', '#blockcart-modal', function (event) {
            if (event.target.id === 'blockcart-modal') {
                $(event.target).remove();
            }
        });
        $body.on('click', '.modal-close', function () {
            $('#blockcart-modal').remove();
        });
    };

    prestashop.on(
            'updateCart',
            function (event) {
                var refreshURL = $('.blockcart').data('refresh-url');
                var requestData = {};
                if (event && event.reason && typeof event.resp !== 'undefined' && !event.resp.hasError) {
                    requestData = {
                        id_customization: event.reason.idCustomization,
                        id_product_attribute: event.reason.idProductAttribute,
                        id_product: event.reason.idProduct,
                        action: event.reason.linkAction
                    };

                    $.post(refreshURL, requestData).then(function (resp) {
                        $('.blockcart').replaceWith($(resp.preview).find('.blockcart'));
                        if (resp.modal) {
                            showModal(resp.modal);
                        }
                    }).fail(function (resp) {
                        prestashop.emit('handleError', {eventType: 'updateShoppingCart',
                            resp: resp});
                    });
                }
                if (event && event.resp && event.resp.hasError) {
                    prestashop.emit('showErrorNextToAddtoCartButton', {errorMessage: event.resp.errors.join('<br/>')});
                }
            }
    );
});
