$(function () {
    $('.cart-table').on('change', '.product-quantity', function () {
        var oldQuntity = $(this).data('quantity');
        var diff = $(this).val() - oldQuntity;
        console.log('diff: ', diff);
        if (diff < 0) {
            qty = diff * -1;
            op = 'down';
        } else {
            qty = diff;
            op = 'up';
        }
        console.log($(this).data('updateQuantityUrl') + '&op=' + op + '&qty=' + qty);
        window.location.replace($(this).data('updateQuantityUrl') + '&op=' + op + '&qty=' + qty);
//        window.location.replace($(this).data('updateQuantityUrl') + '&action=update&ajax=1&op=' + op + '&qty=' + qty);
    })
})