import 'bootstrap';
import 'popper.js';
import './facets.js';
import './checkout.js';
//import prestashop from 'prestashop';
import './product.js';
import './cart.js';
import 'magnific-popup';

$(function () {
//    console.log('prestashop: ', prestashop);
})