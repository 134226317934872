import noUiSlider from 'nouislider';

$(function () {

    const nuSlider = (slider) => {

        if (!slider)
            return
        const queryParameter = 'q'
        const sliderValues = slider.dataset.values.split(',')
        const sliderLength = sliderValues.length
        const filterName = slider.dataset.filter

        const { search, other, founded } = searchUrlFilter(filterName, queryParameter)
        const { firstOf, lastOf } = findRange(sliderValues, founded ? founded.values : [])

        noUiSlider.create(slider, {
            start: [firstOf, lastOf],
            connect: true,
            step: 1,
            range: {
                'min': 0,
                'max': sliderLength - 1
            },
            tooltips: {
                to: v => sliderValues[Number.parseInt(v, 10)],
                from: v => Number.parseInt(v, 10)
            }
        })
            .on('set', (values, handle) => {
                const [start, stop] = values
                const selectedValues = sliderValues.slice(Number.parseInt(start, 10), Number.parseInt(stop, 10) + 1)

                let sv = false
                if (selectedValues.length != sliderLength) {
                    sv = selectedValues
                }
                updateUrl(filterName, sv, other, queryParameter)
            })
    }

    const searchUrlFilter = (filter, queryParameter) => {
        // pobranie parametrów z adresu i stworzenie tablicy obiektów filtrowania
        const search = parseQueryParameters(filter, queryParameter)

        // tablica innych filtrów
        const other = search.filter(q => q.name != filter)

        // wskazany obiekt fitru (pierwszy, jeśli się powtarzają)
        const founded = search.filter(q => q.name == filter)[0]

        return { search: search, other: other, founded: founded }
    }

    const findRange = (all, filtered) => {
        const first = filtered[0]
        const last = filtered[filtered.length - 1]

        const firstOf = all.indexOf(first) !== -1 ? all.indexOf(first) : 0
        const lastOf = all.indexOf(last) !== -1 ? all.indexOf(last) : all.length - 1

        return { firstOf: firstOf, lastOf: lastOf }
    }

    const parseQueryParameters = (filter, param) => {
        const query = new URL(document.location).searchParams
        const q = query.get(param)
        if (!q) {
            return []
        }
        const v = q.split('/').map(e => {
            const x = e.split('-')
            return { name: x[0], values: x.slice(1) }
        })
        return v
    }

    const updateUrl = (filterName, selectedValues, other, queryParameter) => {

        const others = other.map(e => e.name.replace(' ', '+') + '-' + e.values.join('-'))
        let all = others.join("/")
        if (selectedValues) {
            const search = [filterName.replace(' ', '+') + '-' + selectedValues.join('-')]
            all = [search, ...others].join("/")
        }

        const pageSearch = '?' + queryParameter + '=' + all

        document.location.assign(pageSearch)

        // prestashop.emit(
        //     'updateFacets',
        //     pageSearch,
        // )

    }

    const els = document.querySelectorAll(".v-slider")
    els.forEach((el) => {
        nuSlider(el)
    })

    //    nuSlider('heightSlider')

    $('#search_filters').on('change', 'input[type="checkbox"]', function () {
        //        console.log($(this).data('searchString'));
        document.location.assign($(this).data('searchUrl'));
    })
});
